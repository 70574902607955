/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
 
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  html {
      /* 9px / 16px  */
      font-size: 56.25%;
  }

  
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  html {
      /* 8px / 16px = 0.5 = 50% */
      font-size: 50%;
  }

  .navbar-nav li {
    /* margin-left: 0.5rem;
    margin: 2rem 1rem;
    display: flex;
    align-items: start;
    width: 100%; */
  }

  .grid-img {
    height: auto;
    width: 60%;
}

.box-card{
 
  align-items: center;
  }
  

  
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {

  .navbar-nav li {
    /* margin-left: 0.5rem;
    margin: 2rem 1rem;
    display: flex;
    align-items: start;
    width: 100%; */
  }
  .navbar-logo {
    height: auto;
    width: 55%;
}

.subheading {
  font-family: var(--font-body);
  font-size: 12px;
  color: #686868;
}

.box-card{
  align-items: center;
  }
  
 
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {

  .authModule main {
    position: relative;
    z-index: 2;
    min-height: 100vh;
    background-image: url(./images/body-bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.navbar-nav li {
  /* margin-left: 0.5rem;
  margin: 2rem 1rem;
    display: flex;
    align-items: start;
    width: 100%; */
}
  
.navbar-logo {
  height: auto;
  width: 55%;
}


.box-card{
 
  align-items: center;
  }
}

/*
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/