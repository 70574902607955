:root {
  --color-black: #000;
  --color-white: #fff;
  --modal-bg: #13181e;
  --primary-color1: #b700ff;
  --primary-color1-emphasis: #cc4dff;
  --primary-color1-dark: #662680;
  --secondary-color1: #3e4b5a;
  --secondary-color1-emphasis: #3e4b5a;
  --secondary-color1-emphasis-rgb: 62,75,90;
  --accent-color1: #f93;
  --dashboard-bg1: #13181e;
  --body-color1: #aaa;
  --dark-color1: #181619;
  --info-color1: #00c2ff;
  --gray-color1: #a6a6a6;
  --golden-color1: #c5a260;
  --golden-color1-emphasis: #cc9747;
  --bs-primary-rgb1: var(--primary-color1);
  --bs-secondary1-rgb1: var(--secondary-color1);
  --bs-link-color1: var(--primary-color1);
  --bs-secondary1-bg-subtle: #323d48;
  --bs-success-rgb: 7,188,12;
  --navbar-top-height1: 5.25rem;
}

@media screen and (min-width: 1400px) {
  .col-xxl-five {
    flex: 0 0 auto;
    width: 20%;
  }
}

@media screen and (min-width: 1200px) {
  .col-xl-five {
    flex: 0 0 auto;
    width: 20%;
  }
}

.landing-authModule .loader-main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1050;
  position: fixed;
  left: 0;
  top: 0;
}

.landing-authModule .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--primary-color1);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.landing-authModule .subscribe_input .form-control {
  background-color: #170020;
  /* border:var(--color-white) !important ; */
  border-radius: 0px;
  color: var(--color-white);
  padding: 0 1.25rem;
  font-size: 0.875rem;
  height: 3.75rem;
  border: 1px solid var(--color-white);
}

.landing-authModule textarea.form-control {
  height: 16.25rem;
  padding: 1.25rem;
  background-color: transparent;
  border: 1px solid var(--secondary-color1-emphasis);
  resize: none;
}

.landing-authModulem .subscribe_input .form-control:focus {
  background-color: #170020;
  color: var(--color-white);
  outline: none;
  box-shadow: none;
  border-color: var(--secondary-color1-emphasis);
}

.landing-authModule .subscribe_input .form-control-sm {
  height: 2.5rem;
}

.landing-authModule .subscribe_input .form-control-xs {
  height: 1.25rem;
}

.landing-authModule .subscribe_input .form-control::-webkit-input-placeholder {
  color: var(--color-white) !important;
}

.landing-authModule .subscribe_input .form-control::-moz-placeholder {
  color: var(--color-white) !important;
}

.landing-authModule .subscribe_input .form-control:-ms-input-placeholder {
  color: var(--color-white) !important;
}

.landing-authModule .subscribe_input .form-control:-moz-placeholder {
  color: var(--color-white) !important;
}

@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1744px;
  }
}

.landing-authModule .bg-primary-dark {
  background-color: var(--primary-color1-dark) !important;
}

.landing-authModule .bg-info {
  background-color: var(--info-color1) !important;
}

.landing-authModule .bg-dark {
  background-color: var(--dashboard-bg) !important;
}

.landing-authModule .bg-warning {
  background-color: var(--accent-color) !important;
}

.landing-authModule .text-primary {
  color: var(--primary-color1) !important;
}

.landing-authModule .text-warning {
  color: var(--accent-color) !important;
}

.landing-authModule .text-body {
  color: var(--body-color1) !important;
}

.landing-authModule .text-golden {
  color: var(--golden-color1) !important;
}

.landing-authModule .progress {
  --bs-progress-bg: var(--gray-color1);
  --bs-progress-height: 0.5rem;
}

.landing-authModule .icon-16 {
  width: 1.25rem;
}

.landing-authModule .icon-20 {
  width: 1.25rem;
}

.landing-authModule .icon-24 {
  width: 1.5rem;
}

.landing-authModule .icon-30 {
  width: 1.875rem;
}

.landing-authModule .icon-50 {
  width: 3.125rem;
}

.landing-authModule .icon-65 {
  width: 4.0625rem;
}

.landing-authModule .img-thumbnail-36 {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  border-radius: 50%;
  object-fit: cover;
}

.landing-authModule .img-thumbnail-65 {
  width: 4.0625rem;
  height: 4.0625rem;
  padding: 0;
  border-radius: 50%;
  object-fit: cover;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.fs-3 {
  font-size: 1.25rem !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 0.875rem !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}

.fs-7 {
  font-size: 0.625rem !important;
}

/* @media screen and (min-width: 992px) {
  .mw-lg-20 {
    max-width: 20%;
  }
} */

.landing-authModule .border-gray-50 {
  border-color: rgb(112, 112, 112, 0.5) !important;
}

.landing-authModule .list-group-flush {
  --bs-list-group-bg: transparent;
  --bs-list-group-color: var(--color-white);
}

.landing-authModule .btn1 {
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 1.25rem;
  font-family: var(--font-body-bold);
  font-weight: normal;
  letter-spacing: 0.1px;
  padding: 0px 25px;
  /* height: 3.5625rem; */
  line-height: 3.4375rem;
  transition: all 0.3s;
}

.landing-authModule .btn1-sm {
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 2.5rem;
}

.landing-authModule .btn1-xs {
  height: 1.875rem;
  font-size: 1rem;
  line-height: 1.875rem;
}

.landing-authModule .btn1-arrow {
  background-image: url(./images/btn-arrow-svg.svg);
  background-position: right 20px center;
  background-repeat: no-repeat;
  text-align: left;
}

.landing-authModule .btn1-arrow:hover {
  background-position: right 12px center;
}

.landing-authModule .btn1-primary {
  --bs-btn1-bg: transparent;
  --bs-btn1-border-color: var(--secondary-color1);
  --bs-btn1-hover-bg: transparent;
  --bs-btn1-hover-border-color: var(--secondary-color1);
  --bs-btn1-active-bg: transparent;
  --bs-btn1-active-border-color: transparent;
  --bs-btn1-disabled-color: #fff;
  --bs-btn1-disabled-bg: var(--info-color1);
  --bs-btn1-disabled-border-color: var(--info-color1);
}

.landing-authModule .btn1-primary:focus {
  color: var(--color-white);
}

.landing-authModule .btn1-secondary {
  --bs-btn1-bg: var(--secondary-color1-emphasis);
  --bs-btn1-border-color: var(--secondary-color1-emphasis);
  --bs-btn1-hover-bg: transparent;
  --bs-btn1-hover-border-color: var(--secondary-color1-emphasis);
}

.landing-authModule .btn1-golden {
  --bs-btn1-color: var(--color-white);
  --bs-btn1-bg: var(--golden-color1-emphasis);
  --bs-btn1-border-color: var(--golden-color1-emphasis);
  --bs-btn1-hover-bg: transparent;
  --bs-btn1-hover-border-color: var(--golden-color1-emphasis);
  --bs-btn1-active-bg: var(--golden-color1-emphasis);
  --bs-btn1-active-border-color: var(--golden-color1-emphasis);
  --bs-btn1-disabled-color: #fff;
  --bs-btn1-disabled-bg: var(--golden-color1-emphasis);
  --bs-btn1-disabled-border-color: var(--golden-color1-emphasis);
  --bs-btn1-border-width: 1px;
  border-style: solid;
}

.landing-authModule .btn1-golden:focus {
  color: var(--color-white);
}

.landing-authModule .btn1-gradient {
  background: linear-gradient(90deg, #cfb279 0%, #f1dcb1 54.17%, #b39559 100%);
  --bs-btn1-border-color: var(--secondary-color1-emphasis);
  --bs-btn1-hover-bg: transparent;
  --bs-btn1-hover-border-color: var(--white-color);
}

.landing-authModule .btn1-gradient:hover {
  background: transparent;
}

.landing-authModule .btn1-outline-golden {
  --bs-btn1-color: var(--color-white);
  background: transparent;
  --bs-btn1-border-color: var(--body-color1);
  --bs-btn1-hover-bg: var(--color-white);
  --bs-btn1-hover-border-color: var(--color-white);
}

.landing-authModule .btn1-outline-golden:hover {
  color: var(--bs-secondary1-bg-subtle);
}

.landing-authModule .btn1-outline-primary {
  --bs-btn1-color: var(--primary-color1);
  --bs-btn1-border-color: var(--primary-color1);
  --bs-btn1-hover-color: var(--color-white);
  --bs-btn1-hover-bg: var(--primary-color1);
  --bs-btn1-hover-border-color: var(--primary-color1);
  --bs-btn1-active-color: var(--color-white);
  --bs-btn1-active-bg: var(--primary-color1);
  --bs-btn1-active-border-color: var(--primary-color1);
  --bs-btn1-active-shadow: none;
  --bs-btn1-disabled-color: var(--primary-color1);
  --bs-btn1-disabled-bg: transparent;
  --bs-btn1-disabled-border-color: var(--primary-color1);
}
/* 
h1,
.h1 {
  font-size: 3.375rem;
  line-height: 1.3;
  font-family: var(--font-titles);
}

h3,
.h3 {
  font-size: 2rem;
  line-height: 1.111;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1;
}

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1;
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1;
  font-family: var(--font-body-bold);
} */

.title h1,
.title h2,
.title h3,
.title h4,
.title h5,
.title h6 {
  font-family: var(--font-titles);
  font-weight: normal;
  margin: 0;
}

.heading-primary {
  font-family: var(--font-titles);
}

.title .titleIcon img {
  max-width: 2.5rem;
}

.modal {
  --bs-modal-zindex: 1050;
  --bs-modal-bg: var(--modal-bg);
  --bs-modal-padding: 3.125rem;
  --bs-modal-width: 62.5rem;
  --bs-modal-border-radius: 20px;
  --bs-modal-border-width: 0;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.4;
}

.modal .btn1-close {
  --bs-btn1-close-bg: url(./images/cross-circle-svg.svg);
  --bs-btn1-close-opacity: 1;
  background-color: var(--color-white);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  position: absolute;
  right: 1.875rem;
  top: 1.875rem;
}

/**************************/
/* FOOTER */
/**************************/

.footer {
  padding: 6.8rem 0 0 0;
  /* border-top: 1px solid #eee; */
  /* background-color: #000000; */
}

.logo-col {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: block;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 0.4rem;
  margin-left: -2rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #fff;
}

.footer-heading {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
}

.contacts {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  margin-left: -2rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1rem;
  color: #f3f3f3;
  transition: all 0.5s;
}

.footer-nav .active {
  color: #1babf9;
}

.footer-link:hover,
.footer-link:active {
  color: #1babf9;
}

.section-connect {
  background-color: #000000;
  padding: 1rem 0 3rem 0rem;
}

.hover-fx {
  font-size: 16px;
  display: inline-block;
  cursor: pointer;

  width: 26px;
  height: 26px;

  border-radius: 50%;
  text-align: center;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 300ms;
}

.hover-fx:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-sizing: content-box;
  box-shadow: 0 0 0 3px #1babf9;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 300ms;
}

.hover-fx:hover {
  color: #1babf9;
}

.hover-fx:hover:after {
  opacity: 1;
  transform: scale(1.15);
}

.section-subscribe {
  background: #170020;
  padding: 0rem;
  border: transparent;
}

.landing-authModule .nav-link a {
  display: flex;
  align-items: center;
}

.section-how .card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: transparent;
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: none;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: transparent !important;
  border-radius: var(--bs-card-border-radius);
}
.patner-section-how .card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: transparent;
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: none;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: transparent !important;
  border-radius: var(--bs-card-border-radius);
}

.landing-authModule .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  color: #f3f3f3;
  bottom: 0;
  left: 0;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.landing-authModule .patner-card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  color: #f3f3f3;
  bottom: 0;
  left: 0;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/*  */
.landing-authModule .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;

  /* Because we want header to be sticky later */
  height: 4.6rem;

  position: relative;
  width: 100%;
  z-index: 1000;
}

.landing-authModule .grid--box-cols {
  grid-template-columns: repeat(2, 1fr);
}

.landing-authModule .grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.landing-authModule .grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.landing-authModule .section-work {
  /* background:url(./images/page-bg.jpg) ; */

  min-height: auto;
}

.landing-authModule .logo {
  height: 2.2rem;
}

/**************************/
/* NAVIGATION */
/**************************/

/* .landing-authModule .navbar-nav .nav-link.active,
.landing-authModule .navbar-nav .nav-link.show {
  color: var(--color-white);
  border-bottom: 2px solid  var(--color-white);
} */

.landing-authModule .navbar-nav .nav-link.active,
.landing-authModule .navbar-nav .nav-link.show::after {
  color: var(--primary-color1);
  border-bottom: 2px solid var(--primary-color1);
}

.landing-authModule .navbar-nav li {
  margin-left: 1rem;
}

.landing-authModule .nav-link {
  display: block;
  
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--color-white);
  text-decoration: none;
  margin-left: 1.5rem;

  background: 0 0;
  border-bottom: 2px solid transparent ;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.landing-authModule .nav-link:focus,
.landing-authModule .nav-link:hover {
  color: var(--primary-color1);
  border-bottom: 2px solid var(--primary-color1);
}

.landing-authModule .navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: var(--color-white);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

/* MOBILE */
.btn1-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.landing-authModule .icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #f3f3f3;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.blue-line,
.top-and-bottom {
  position: relative;
}

.blue-line::after {
  content: "";
  position: absolute;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px 0px #00C2FF, 0px -2px 4px 0px #00C2FF, 0px -4px 4px 0px rgba(0, 194, 255, 0.50), 0px 4px 4px 0px rgba(0, 194, 255, 0.50);;
  width: 100%;
  height: 3px;
  bottom: -0.8rem;
  left: 0;
  z-index: 1;
}

.top-and-bottom::after {
  content: "";
  position: absolute;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px 0px #b700ff, 0px -2px 4px 0px #b700ff,
    0px -4px 4px 0px rgba(183, 0, 255, 0.5),
    0px 4px 4px 0px rgba(183, 0, 255, 0.5);
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  margin: 0px;
  z-index: 2;
}

.landing-authModule .title_secondary {
  font-family: var(--font-titles);
}

.sticky.is-sticky {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.landing-authModule .input-group-text {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0px;
}

.landing-authModule .subheading {
  font-family: var(--font-body);
  font-size: 16px;
  color: #686868;
}

.landing-authModule ul.social-links {
  display: flex;
}
.landing-authModule ul.social-links li {
  list-style: none;
  /* margin: 0 5px; */
  height: 2.5rem;
  width: 2.5rem;
  color: #f3f3f3;
  border: 2px solid #00c2ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  transition: all ease 0.3s;
}

.landing-authModule ul.social-links li:hover {
  color: #f3f3f3;
  background: #00c2ff;
  cursor: pointer;
}

.landing-authModule .connect-card .card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: transparent;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  /* background-color: var(--bs-card-bg); */
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

/* Navbar */

.landing-authmodule header {
  width: 100%;
  height: 100vh;
  /* background: url(hero.jpg) no-repeat 50% 50%; */
  background-size: cover;
}

.landing-authModule .logo {
  line-height: 60px;
  position: fixed;
  float: left;
  margin: 16px 46px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
}

.landing-authmodule .navbar {
  background-color: #000;
  height: auto;
}

.landing-authModule .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  background: #000;
}

.landing-authModule .navbar-logo {
  height: auto;
  width: 80%;
}

/* PAGE _2 */

.landing-authModule .section-explore {
  padding: 6rem 0;
}

.landing-authModule .explore-text-area h1 span {
  background: linear-gradient(75deg, #be38d3 0%, #00a2ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 1rem;
}

.landing-authModule .grid-img {
  height: auto;
  width: 100%;
}

.landing-authmodule main {
  position: relative;
  z-index: 2;

  margin-top: 3.4rem;
}

.landing-authModule .main-bg {
  /* background: url("../images/page-bg.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.landing-authModule .submit-form .form-control {
  background-color: transparent;
  border: transparent;
  border-bottom: 1px solid #f3f3f3 !important;
  border-radius: 0px;
  color: var(--color-white);
  padding: 0 1.25rem;
  font-size: 0.875rem;
  height: 3.75rem;
}

.landing-authModule textarea.form-control {
  height: 16.25rem;
  padding: 1.25rem;
  background-color: transparent;
  border: none;
  resize: none;
}

.landing-authModule .box-card {
  padding: 64px 0;
}

.landing-authModule .btn1-submit {
  --bs-btn1-bg: #fff;
  border-radius: 0;
  --bs-btn1-border-color: transparent;
  --bs-btn1-hover-bg: #f3f3f3;
  --bs-btn1-hover-border-color: #f3f3f3;
  --bs-btn1-active-bg: transparent;
  --bs-btn1-active-border-color: transparent;
  --bs-btn1-disabled-color: #fff;
  --bs-btn1-disabled-bg: var(--info-color1);
  --bs-btn1-disabled-border-color: var(--info-color1);
}

.sectionPartner {
  padding: 2rem 6rem 2rem 6rem;
}
.square-holder {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #262626;
  height: 220px;
  width: 100%;
}

.square-holder img {
  width: 55%;

  height: auto;

  transition: all 0.3s;
}

.square-holder:hover img {
  filter: none;
}
.headTexture {
  background: linear-gradient(75deg, #be38d3 0%, #00a2ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 1rem;
}
.icon-400{
  /* width: 33rem; */
  height: 33rem;
}

@media screen and (max-width: 992px) {
  /* .landing-authModule .card-img-overlay {
    padding-top: 6rem !important;
  } */
  .landing-authModule .patner-card-img-overlay {
    padding-top: 13rem !important;
  }
  .icon-400{
    width: 25rem;
  }
  .section-how{
    /* padding: 5rem 0; */
    padding-top: 2rem;
    padding-bottom: 2rem;
  } 
  .patner-section-how{
    /* padding: 5rem 0; */
    padding-top: 3rem;
    padding-bottom: 10rem;
  } 

}
@media screen and (max-width: 765px){
  .icon-400{
    width: 100%;
    
  }
  .card-img-top{
    width: 100%;
    /* margin: 0 auto; */
  }
  .row-card{
    flex-direction: column-reverse !important;
  }
  /* .hero_image {
    text-align: center;
  } */
  .landing-authModule p{
    font-size: 20px !important; 
   }
  .section-connect .title_secondary {
    font-size: 40px !important;
    margin-bottom: 20px;
    line-height: 2
  }
  .landing-authModule h1{
    font-size: 6rem !important;
    /* text-align: center; */
  }
  .hero_section--card {
    /* padding-bottom: 50px; */
    text-align: center;
  }
  
  .let-play{
    justify-content: center;
  }
  .footer  h6{
    font-size: 13px;
  }
  .footer-link:link, .footer-link:visited {
    font-size: 14px;
  }
  .landing-authModule .subscribe_input .input-group-text{
    font-size: 10px;
  }
  .landing-authModule .subscribe_input .form-control{
    font-size: 10px;
  }
  .section-subscribe{
    padding: 25px;
  }
  .compnay-image{
    width: 70% !important;
  }
  .compnay-image-image{
    text-align: center;
  }
  .premium-button{
    width: 90% !important;
    font-size: 14px;
  }
  .footer .resposive-text{
    font-size: 20px;
  }
  /* .btn1-primary{
    font-size: 12px !important;
  } */
  .footer {
    padding: 5px !important;
  }
  .landing-authModule ul.social-links li {
    width: 3.5rem !important;
    height: 3.5rem !important;
  }
  .landing-authModule .btn1{
    padding: 5px 25px;
    font-size: 14px;
  }
}
@media screen and (max-width: 476px){
  .icon-400{
    width: 100%;
  }
  .card-img-top{
    width: 100%;
    /* margin: 0 auto; */
  }
  .row-card{
    flex-direction: column-reverse !important;
    /* text-align: center; */
  }
  /* .hero_image{
    text-align: center;
  } */
  .landing-authModule p{
   font-size: 20px !important; 
  }
  
  .top-and-bottom::after {
    left: 4% !important;
    width: 93% !important;
  }
  .section-connect .title_secondary {
    font-size: 40px !important;
    margin-bottom: 20px;
    line-height: 2
  }
  .landing-authModule h1{
    font-size: 5rem !important;
    /* text-align: center; */
  }
  .hero_section--card {
    /* padding: 25px; */
    text-align: center;
  }
  .let-play{
   justify-content: center;
  }
  .footer  h6{
    font-size: 13px;
  }
  .footer-link:link, .footer-link:visited {
    font-size: 14px;
  }
  .landing-authModule .subscribe_input .input-group-text{
    font-size: 10px;
  }
  .landing-authModule .subscribe_input .form-control{
    font-size: 10px;
  }
  .section-subscribe{
    padding: 25px;
  }
  .compnay-image{
    width: 60% !important;
  }
  .compnay-image-image{
    text-align: center;
  }
  .premium-button{
    width: 90% !important;
    font-size: 14px;
  }
  .footer .resposive-text{
    font-size: 20px;
  }
  /* .btn1-primary{
    font-size: 12px !important;
  } */
  .footer {
    padding: 5px !important;
  }
  .landing-authModule ul.social-links li {
    width: 3.5rem !important;
    height: 3.5rem !important;
  }
  .landing-authModule .btn1{
    padding: 5px 25px;
    font-size: 14px;
  }
}
.d11_button{
  display: block;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    text-align: center;
    padding: 0.5rem 1rem;
    transition: all 0.25s ease-in-out;
    /* font-family: var(--e-global-typography-accent-font-family), Sans-serif; */
    text-transform: uppercase;
    font-weight: bold;
    /* font-style: italic; */
}
.d11_button:hover{
  background-color: #FFBD59;
  color: black;
  transform: scale(1.1);
  cursor: pointer;
}
.h1_text{
  font-size: 2.5rem !important;
}
.homecard_body {
	color: #FFF;
	font-family: var(--font-body);
	font-size: 32px;
	font-style: normal;
}

.hero_section{
  position: relative;
  min-height: 600px;
  /* background-image: url(./images/header_bg.png); */
  background-size: cover;
  background-position: center;
  background-color: #070A0C;
}
.hero_section_main{
  background-image: url(./images/header_bg.png);
}
.hero_section--card{
  position: relative;
}

.homecard {
	background: transparent;
	border: transparent;
  min-height: 600px;
}
.hero_image{
  position: relative;
  /* top: -1rem; */
  z-index: 9;
}
.compnay-image{
  width: 35%;
}
.premium-button{
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
  width: 45%;
  margin-bottom: 10px;
  text-align: center;
}

.aboutsection {
  position: relative;
  /* min-height: 600px; */
  background-image: url(./images/Aboutus.jpg);
  background-size: cover;
  background-position: center;
  padding: 16px;
}

.section-explore h2 , .aboutsection h2{
  font-size: 24px;
  font-family: var(--font-titles);
}

.section-explore ,.aboutsection p{
  font-size: 16px !important;
}

.sliceHeading{
  max-width: 27ch;
}



@media screen and (max-width:736px){
  .flip-cards{
    display: flex;
    flex-direction: column-reverse;
  }
  .sliceHeading{
    margin-top: 12px;
    text-align: left;
  }
 
}